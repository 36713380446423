<template>
  <v-card height="400px" color="#273142">
    <v-card-title class="card-header">
      <h3 class="ml-3">{{ title }}</h3>
    </v-card-title>

    <v-card-text class="mt-3">
       <PlaceHolder
        :src="require('@/assets/img/svg/no-notifications.svg')"
        title="No canclled orders available"
        text=""
        v-if="orders.length === 0"
      />
      <v-list
        class="pa-0 "
        v-for="(order, index) in orders"
        :key="index"
        :color="ant"
      >
        <orderComponent class="" :order="order" v-ripple="{ center: true }" />
        <v-divider class="my-0"></v-divider>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";

export default {
  name: "cancelled",
  components: {
    orderComponent: () =>
      import("@/modules/writersDashboard/available/orderComponent"),
    PlaceHolder: () => import("@/components/core/PlaceHolder"),

  },
  mixins: [colorMxn,validationMxn],
  data() {
    return {
      orders: [
        {
          topic:
            "Causal Essay - Nonbinary as a gender identity python and it environments",
          pages: "2",
          type: "Essay (any)",
          language: "English (UK)",
          status: "Draft",
          amount: "7",
          time: "2 days remaining",
          bids: [],
        },
        {
          topic: "R Studio",
          pages: "2",
          type: "Essay (any)",
          language: "English (UK)",
          status: "Draft",
          amount: "7",
          time: "2 days remaining",
          bids: []
        },
        {
          topic: "SPSS",
          pages: "2",
          type: "Essay (any)",
          language: "English (UK)",
          status: "Draft",
          amount: "7",
          time: "2 days remaining",
          bids: []
        }
      ],
      model: ""
    };
  },
  computed: {
    ...mapGetters(['getSession']),
    title() {
      let title = "All Available Orders";
      switch (this.$route.name) {
        case "all":
          title = "All Available Orders";
          break;
        case "invited":
          title = "Customer Invitations";
          break;
        case "hidden":
          title = "Hidden Orders";
          break;
        case "bidding":
          title = "My Open Bids";
          break;
        case "in-progress":
          title = "My Orders in Progress";
          break;
        case "completed":
          title = "My Completed Ordes";
          break;
        case "cancelled":
          title = "My Cancelled Orders";
          break;
        default:
          title = "All Available Orders";
          break;
      }
      return title;
    }
  },
  mounted() {
    this.getOrders();
  },
  methods: {
     async getOrders() {
      const payload = {
        page: 1,
        order_status: 'closed',
        writer_id: this.getSession.writer.id,
      };
      const fullPayload = {
        params: payload,
        endpoint: '/writer-orders'
      };
      const response = await this.performGetActions(fullPayload);
      this.orders = response.data;
    },
  },

};
</script>
